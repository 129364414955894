.MuiPaper-root{
    background-color: transparent;
}

.form-check-input:checked{
    background-color: green !important;
}


p {
    margin: 0 !important;
    margin-bottom: 0px !important;
}

.mb-3{
    margin-bottom: 0 !important;
}

.cursor{
    cursor: pointer;
}

.MuiDataGrid-root{
    letter-spacing:0px !important;
    font-size: 0.71rem !important;
}
